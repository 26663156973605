



export const getUrlParams = () => {

        const queryString = window.location.search;

        const params = new URLSearchParams(queryString);


        let id = params.get('id')


        console.log('id',id)

     
        return id;

    }