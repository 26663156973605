{
    "scrambled_words" : {
        "title" : "Scrambled",
        "description" : "Click on the letters to unscamble the wedding related word",
        "rounds" : {
            "0" : {
                "question" : "simdbdaire",
                "answer" : "bridesmaid"
            },
            "1" : {
                "question" : "ni sawl",
                "answer" : "in laws"
            },
            "2" : {
                "question" : "cxgginhean vwso",
                "answer" : "exchanging vows"
            },
            "3" : {
                "question" : "tbse anm",
                "answer" : "best man"
            },
            "4" : {
                "question" : "nmhaecpga sotta",
                "answer" : "champagne toast"
            },
            "5" : {
                "question" : "rtifs nacde",
                "answer" : "first dance"
            },
            "6" : {
                "question" : "itucgnt eht caek",
                "answer" : "cutting the cake"
            },
            "7" : {
                "question" : "yhoneomon",
                "answer" : "honeymoon"
            },
            "8" : {
                "question" : "saecnpa",
                "answer" : "canapes"
            },
            "9" : {
                "question" : "dinwegd dsers",
                "answer" : "wedding dress"
            },
            "10" : {
                "question" : "ttncfeio",
                "answer" : "confetti"
            },
            "11" : {
                "question" : "togepaporhrh",
                "answer" : "photographer"
            },
            "12" : {
                "question" : "mgirraae ftcierectia",
                "answer" : "marriage certificate"
            },
            "13" : {
                "question" : "idnegwd berakftas",
                "answer" : "wedding breakfast"
            },
            "14" : {
                "question" : "enh nghit",
                "answer" : "hen night"
            }
        }
    },
    "emoji_game" : {
        "title" : "Emoji game",
        "description" : "Guess the wedding related phrase from the emoji sequence",
        "rounds" : {
            "0" : {
                "question" : "👩 🧹 🧼 🏅",
                "answer" : "maid of honour"
            },
            "1" : {
                "question" : "☝️💃",
                "answer" : "first dance"
            },
            "2" : {
                "question" : "🌸 👧",
                "answer" : "flower girl"
            },
            "3" : {
                "question" : "💧💧💧🍰",
                "answer" : "three tier cake"
            },
            "4" : {
                "question" : "🍯🌙",
                "answer" : "honeymoon"
            },
            "5" : {
                "question" : "✂️🍰",
                "answer" : "cutting the cake"
            },
            "6" : {
                "question" : "📄👦",
                "answer" : "page boy"
            },
            "7" : {
                "question" : "💍🐻",
                "answer" : "ring bearer"
            },
            "8" : {
                "question" : "🏆👰",
                "answer" : "trophy wife"
            },
            "9" : {
                "question" : "🍾🍞",
                "answer" : "champagne toast"
            },
            "10" : {
                "question" : "👰✌️🐝",
                "answer" : "bride to be"
            },
            "11" : {
                "question" : "👰🚿",
                "answer" : "bridal shower"
            }
        }
    },
    "finleys_films" : {
        "title": "Finley's Films",
        "description" : "Guess the movie finley or mabel is starring in",
        "rounds": {
            "0" : {
                "question" : "",
                "answer" : "castaway"
            },
            "1" : {
                "question" : "",
                "answer" : "lady and the tramp"
            },
            "2" : {
                "question" : "",
                "answer" : "titanic"
            },
            "3" : {
                "question" : "",
                "answer" : "forest gump"
            },
            "4" : {
                "question" : "",
                "answer" : "et"
            },
            "5" : {
                "question" : "",
                "answer" : "alien"
            },
            "6" : {
                "question" : "",
                "answer" : "the lion king"
            },
            "7" : {
                "question" : "",
                "answer" : "toy story"
            },
            "8" : {
                "question" : "",
                "answer" : "american beauty"
            }
        }
        
    },   
    "fill_the_blanks" : {
        "title": "Fill the blanks",
        "description" : "Pick the correct vowel to fill in the blank",
        "rounds" : {
            "0" : {
                "question" : "_ngag_m_nt",
                "answer" : "engagement"

            },
            "1" : {
                "question" : "b_ddow p_rk",
                "answer" : "baddow park"
            },
            "2" : {
                "question" : "h_neym__n",
                "answer" : "honeymoon"
            },
            "3" : {
                "question" : "for_v_r",
                "answer" : "forever"
            },
            "4" : {
                "question" : "_nv_tat_ons",
                "answer" : "invitations"
            },
            "5" : {
                "question" : "m_rri_ge",
                "answer" : "marriage"
            },
            "6" : {
                "question" : "g_rl_nd",
                "answer" : "garland"
            },
            "7" : {
                "question" : "h_ad tabl_",
                "answer" : "head table"
            },
            "8" : {
                "question" : "bo_q_et",
                "answer" : "bouquet"
            },
            "9" : {
                "question" : "sp__ch",
                "answer" : "speech"
            },
            "10" : {
                "question" : "c_r_mony",
                "answer" : "ceremony"
            },
            "11" : {
                "question" : "r_c_ption",
                "answer" : "reception"
            },
            "12" : {
                "question" : "c_ntr_pi_c_s",
                "answer" : "centrepieces"
            },
            "13" : {
                "question" : "c_l_brat_",
                "answer" : "celebrate"
            },
            "14" : {
                "question" : "m_n_gamy",
                "answer" : "monogamy"
            },
            "15" : {
                "question" : "l_ts _f l_ve",
                "answer" : "lots of love"
            },
            "16" : {
                "question" : "j_w_ll_ry",
                "answer" : "jewellery"
            },
            "17" : {
                "question" : "b_llrop_s r_tr_at",
                "answer" : "bellropes retreat"
            }
        }
    },
    "face_merge" : {
        "title": "Face Merge",
        "description" : "Guess the celebrity or character the beautiful brides to be's faces have been merged with",
        "rounds" : {
            "0" : {
                "question" : "",
                "answer" : "barbie"
            },
            "1" : {
                "question" : "",
                "answer" : "katniss"
            },
            "2" : {
                "question" : "",
                "answer" : "wanda"
            },
            "3" : {
                "question" : "",
                "answer" : "merida"
            },
            "4" : {
                "question" : "",
                "answer" : "adele"
            },
            "5" : {
                "question" : "",
                "answer" : "daenerys"
            },
            "6" : {
                "question" : "",
                "answer" : "princess peach"
            },
            "7" : {
                "question" : "",
                "answer" : "princess fiona"
            },
            "8" : {
                "question" : "",
                "answer" : "princess leia"
            },
            "9" : {
                "question" : "",
                "answer" : "jack sparrow"
            },
            "10" : {
                "question" : "",
                "answer" : "harley quinn"
            },
            "11" : {
                "question" : "",
                "answer" : "shakira"
            },
            "12" : {
                "question" : "",
                "answer" : "amy winehouse"
            },
            "13" : {
                "question" : "",
                "answer" : "wonder woman"
            },
            "14" : {
                "question" : "",
                "answer" : "little red riding hood"
            },
            "15" : {
                "question" : "",
                "answer" : "rapunzel"
            }
      
       
        }
    }


}