{
    "1" : {
        "clue":  "I dry as I get wetter.",
        "answer": "towel",
        "heartToken": "lewot1"
    },
    "2" : {
        "clue":  "I have hands but cannot clap?",
        "answer": "clock",
        "heartToken": "kcolc2"
    },
    "3" : {
        "clue": "You cut me on a table, but I’m never eaten.",
        "answer": "cards",
        "heartToken": "sdrac3"
    },
    "4" : {
        "clue": "have lots of stars, but I’m not the sky. I’ll be sitting here quietly until you need me.",
        "answer": "tv",
        "heartToken": "vt4"
    },
    "5" : {
        "clue":  "I have four legs, but I don’t have any feet.",
        "answer": "table",
        "heartToken": "elbat5"
    },
    "6" : {
        "clue":  "Adding bubbles to me is fun. You usually get in me at night before your day is done.",
        "answer": "bath",
        "heartToken": "thab6"
    },
    "7" : {
        "clue":  "I make two people out of one. What am I?",
        "answer": "mirror",
        "heartToken": "rorrim7"
    },
    "8" : {
        "clue":  "I get answered even though I never ask a question. What am I?",
        "answer": "doorbell",
        "heartToken": "llebrood8"
    },
    "9" : {
        "clue":  "What is full of holes but still holds water?",
        "answer": "sponge",
        "heartToken": "egnops9"
    },
    "10" : {
        "clue":  "If you want to eat, then take a seat",
        "answer": "dining chair",
        "heartToken": "riahcgninid10"
    },
    "11": {
        "clue":  "What four-letter word begins with 'f' and ends with 'k'",
        "answer": "fork",
        "heartToken": "korf11"
    },
    "12": {
        "clue":  "What’s white, sticky, and better to spit than to swallow?",
        "answer": "toothpaste",
        "heartToken": "etsaphtoot12"
    },
    "13": {
        "clue":  "What has a neck but no head",
        "answer": "bottle",
        "heartToken": "elttob13"
    },
    "14": {
        "clue":  "I get bigger the more you blow me",
        "answer": "balloon",
        "heartToken": "noollab14"
    },
    "15": {
        "clue":  "I have a bark, but I have no bite. I'm rarely still, but I never wander. What am I?",
        "answer": "tree",
        "heartToken": "eert15"
    },
    "16": {
        "clue":  "I've got charcoal in my belly, and metal feet. Once I am hot, it's time to eat. What am I?",
        "answer": "bbq",
        "heartToken": "qqb16"
    },
    "17": {
        "clue":  "You'll find me on the floor, and I'm comfy to walk on",
        "answer": "rug",
        "heartToken": "gur17"
    },
    "18": {
        "clue": "People at the gym, often say to each other. How much do you......?",
        "answer": "bench",
        "heartToken": "hcneb18"
    },
    "19": {
        "clue":  "A place to relax & chill, with lots of bubbles",
        "answer": "jacuzzi",
        "heartToken": "izzucaj19"
    },
    "20": {
        "clue":  "I welcome every guest but never say a word.",
        "answer": "welcome mat",
        "heartToken": "tamemoclew20"
    }


}